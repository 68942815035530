// 引用axios
import axios, { AxiosRequestHeaders, AxiosResponse, AxiosError, ResponseType } from 'axios'

function handleAxios (method: string, url: string, data: object, params: object, success: (res: AxiosResponse) => void, failure: (error: AxiosError) => void, headers?: AxiosRequestHeaders, responseType?: ResponseType) {
  axios({
    method: method,
    url: url,
    data: data,
    params: params,
    headers: headers,
    baseURL: process.env.VUE_APP_BASICURL,
    responseType: responseType
  })
    .then(success)
    .catch(failure)
}

export class HttpHandler {
  Get (url: string, params: object, success: (res: AxiosResponse) => void, failure: (error: AxiosError) => void, headers?: AxiosRequestHeaders, responseType?: ResponseType) {
    handleAxios('GET', url, {}, params, success, failure, headers, responseType)
  }

  Post (url: string, data: object, params: object, success: (res: AxiosResponse) => void, failure: (error: AxiosError) => void, headers?: AxiosRequestHeaders, responseType?: ResponseType) {
    handleAxios('POST', url, data, params, success, failure, headers, responseType)
  }

  Send (method: string, url: string, data: object, params: object, success: (res: AxiosResponse) => void, failure: (error: AxiosError) => void, headers?: AxiosRequestHeaders, responseType?: ResponseType) {
    handleAxios(method, url, data, params, success, failure, headers, responseType)
  }
}
