import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "my_result"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [[_vShow, _ctx.isShow]]), _createElementVNode("div", null, [_createVNode(_component_el_button, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.qinit())
  }, {
    default: _withCtx(() => [_createTextVNode("开始扫描")]),
    _: 1
  }), _createVNode(_component_el_button, {
    onClick: _cache[1] || (_cache[1] = $event => _ctx.qstop())
  }, {
    default: _withCtx(() => [_createTextVNode("停止扫描")]),
    _: 1
  })]), _createElementVNode("div", null, "识别的条形码为：" + _toDisplayString(_ctx.codeNumber), 1)]);
}