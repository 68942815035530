<template>
    <div>
        <div v-show="isShow" id="my_result">
        </div>
        <div>
            <el-button @click="qinit()">开始扫描</el-button>
            <el-button @click="qstop()">停止扫描</el-button>
        </div>
        <div>识别的条形码为：{{ codeNumber }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import Quagga from '@ericblade/quagga2'
import { HttpHandler } from '@/common/http'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'ScanView',
  setup () {
    const http: HttpHandler | undefined = inject('http')
    return { http }
  },
  data () {
    return {
      isShow: false,
      codeNumber: ''
    }
  },
  created () {
    this.getEnv()
    // this.downloadFile()
  },
  methods: {
    // 开始扫描
    qinit () {
      const _self = this
      Quagga.init({
        inputStream: {
          name: 'Live',
          type: 'LiveStream', // ImageStream、VideoStream或LiveStream（默认值）
          target: '#my_result',
          constraints: {
            width: 200,
            height: document.body.clientWidth
          }
        },
        decoder: {
          readers: ['code_128_reader', 'ean_reader', 'upc_reader', 'code_39_reader'],
          multiple: false
        }
      }, function (err) {
        if (err) {
          alert(err)
          return
        }
        Quagga.start()
        Quagga.onDetected(function (data) {
          _self.codeNumber = data.codeResult.code != null ? data.codeResult.code : ''
          _self.isShow = false
          Quagga.stop()
        })
      })
      this.isShow = true
    },
    // 停止扫描
    qstop () {
      this.isShow = false
      Quagga.stop()
    },
    getEnv () {
      this.http?.Get(process.env.VUE_APP_BASICURL + 'Basic/GetEnv', {}, res => {
        console.log(res.data)
      }, err => {
        ElMessageBox.alert(err.message, '错误', {
          confirmButtonText: '好的'
        })
      })
    },
    downloadFile () {
      this.http?.Get(process.env.VUE_APP_BASICURL + 'Basic/GetFile', {
        fileName: 'IMG20170101111921.jpg'
      }, res => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(new Blob([res.data]))
        link.download = 'IMG20170101111921.jpg'
        link.click()
      }, err => {
        ElMessageBox.alert(err.message, '错误', {
          confirmButtonText: '好的'
        })
      }, {
        AppKey: 'eec0c7c90521cd43'
      }, 'blob')
    }
  }
})
</script>
<style lang="less">
canvas.drawingBuffer {
    position: absolute;
    left: 0;
}

#my_result {
    position: relative;
}
</style>
